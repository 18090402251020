import { useEffect, useState } from "react";
import Slider from "react-slick";
import { ClientOnly } from "remix-utils/client-only";
import Container from "~/components/layout/Container";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import type { blogPosts } from "../blog+/_index";




export default function BlogNews({ blog }: { blog: typeof blogPosts }) {
  const [mounted, setMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setMounted(true);
    setIsMobile(window.innerWidth < 768);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
  };

  if (!mounted) { return null; }

  return (
    <section className="py-16 overflow-hidden">
      <Container className="flex-wrap">
        <h2 className="text-3xl font-bold text-center mb-12">Blog / Bizden Haberler</h2>
        <div className="block justify-center w-full [&_.slick-track]:!flex [&_.slick-track]:!w-[3000px]">
          <ClientOnly fallback={<div>Loading...</div>}>
            {() => (
              <Slider {...settings}>
                {blog.map((post) => (
                  <div key={post.id} className="px-2">
                    <Card>
                      <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
                      <CardHeader>
                        <CardTitle>{post.title}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="mb-2" dangerouslySetInnerHTML={{ __html: post?.excerpt.length > 100 ? `${post?.excerpt.slice(0, 100)}...` : post?.excerpt }} />
                        <p className="text-sm text-gray-500">{post.date}</p>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </Slider>
            )}
          </ClientOnly>
        </div>
      </Container>
    </section>
  );
}