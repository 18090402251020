import { type MetaFunction, useLoaderData } from "@remix-run/react";
import { ClientOnly } from "remix-utils/client-only";
import Container from "~/components/layout/Container";
import { blogPosts } from "./blog+/_index";
import BlogNews from "./features/BlogNews";
import ImageCarousel from "./features/ImageCarousel";
import OfferBanner from "./features/OfferBanner";
import References from "./features/References";
import ServicesComponent from "./features/Services";
import Slogans from "./features/Slogans";
import { HizmetListesi } from "./hizmetlerimiz+/_index";

export const references = [
  { name: "E.C.A Kombi", logo: "/assets/eca-kombi-logo.webp", link: "https://eca.com.tr/" },
  { name: "Baymak Kombi", logo: "/assets/baymak-kombi-logo.webp", link: "https://baymak.com.tr/" },
  { name: "Vaillant Kombi", logo: "/assets/vaillant-kombi-logo.webp", link: "https://www.vaillant.com.tr" },
  { name: "Bosch Kombi", logo: "/assets/bosch-kombi-logo.webp", link: "https://www.bosch-homecomfort.com/tr/tr/ocs/residential/kombiler-754580-c/" },
  { name: "Demirdöküm Kombi", logo: "/assets/demirdokum-kombi-logo.webp", link: "https://demirdokum.com.tr/anasayfa/" },
  { name: "Viessmann Kombi", logo: "/assets/viessmann-kombi-logo.webp", link: "https://www.viessmann.com.tr" },
  { name: "Buderus Kombi", logo: "/assets/buderus-kombi-logo.webp", link: "https://www.buderus.com/tr/tr/" },
  { name: "Protherm Kombi", logo: "/assets/protherm-kombi-logo.webp", link: "https://www.protherm.com.tr" },
  { name: "Daikin Kombi", logo: "/assets/daikin-kombi-logo.webp", link: "https://www.daikin.com.tr/" },
  { name: "Alarko Kombi", logo: "/assets/alarko-kombi-logo.webp", link: "https://www.alarko-carrier.com.tr/" },
  { name: "Arçelik Kombi", logo: "/assets/arcelik-kombi-logo.webp", link: "https://www.arcelik.com.tr/kombi" },
  { name: "Ferroli Kombi", logo: "/assets/ferroli-kombi-logo.webp", link: "https://www.ferroli.com.tr/" },
  { name: "Airfel Kombi", logo: "/assets/airfel-kombi-logo.webp", link: "https://airfel.com/tr/tr/" },
  { name: "Sanica Isı Kombi", logo: "/assets/sanica-isi-kombi-logo.webp", link: "https://www.sanicaisi.com.tr/kombi/" },
  { name: "Beko Kombi", logo: "/assets/beko-kombi-logo.webp", link: "https://www.beko.com.tr/kombi" },
];
export const loader = async () => {
  return { references: references.sort((a, b) => a.name.localeCompare(b.name)), blog: blogPosts, services: HizmetListesi };
};
export const meta: MetaFunction = () => {
  return [
    { title: "Al-De Mühendislik | Isıtma, Soğutma ve Su Isıtma Sistemleri" },
  ]
}
export default function Page() {
  const { references, blog, services } = useLoaderData<typeof loader>();
  return (
    <div className="mt-[140px] md:mt-[134px]">
      <Container className="!p-0 md:!p-0">
        <ClientOnly fallback={<div>Loading...</div>}>
          {() => <ImageCarousel />}
        </ClientOnly>
      </Container>
      <ServicesComponent services={services} />
      <Slogans />
      <BlogNews blog={blog} />
      <OfferBanner />
      <References references={references} />
    </div>
  )
}
