import { useEffect, useState } from "react";
import Slider from "react-slick";

const slides = [
  {
    image: "/assets/alde-muhendislik-slider-1.webp",
    title: "",
    subTitle: "",
    description: "",
    cta: "",
  },
  //  {
  //   image: "/assets/Utb-trader-support-main-slider-2.webp",
  //   title: "Doğru Sistemleri Seçin",
  //   subTitle: "Isıtma ve Soğutma Sistemleri",
  //   description: "Al-DE Mühendislik ile ısıtma ve soğutma sistemlerinizi optimize edin ve enerji verimliliğini artırarak maliyetlerinizi düşürün.",
  //   cta: "TEKLİF AL",
  // }, {
  //   image: "/assets/Utb-trader-support-main-slider-3.webp",
  //   title: "Zamandan Tasarruf Edin",
  //   subTitle: "Isıtma ve Soğutma Sistemleri",
  //   description: "Al-DE Mühendislik ile ısıtma ve soğutma sistemlerinizi optimize edin ve enerji verimliliğini artırarak maliyetlerinizi düşürün.",
  //   cta: "TEKLİF AL",
  // }
];

export default function ImageCarousel() {
  // const [mounted, setMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    adaptiveHeight: true,
  };


  return (
    <Slider {...settings} className="w-full mt-8">
      {slides.map((slide, index) => (
        <div key={slide.image} className="relative">
          <div className="w-full h-auto object-cover mix-blend-hard-light overflow-hidden">
            <img src={slide.image} alt={slide.title} className="!w-[100%] h-[180px] md:h-auto md:w-full object-cover mix-blend-multiply" />
          </div>
          <div className="absolute border-2 border-white inset-0 bg-opacity-50 flex items-center justify-start">
            <div className="text-white font-raleway w-[90%] m-auto">
              <h3 className="text-xl font-medium mb-8 text-xl md:text-[34px]">{slide.subTitle}</h3>
              <h2 className="text-4xl font-bold mb-8 text-xl md:text-[48px]">{slide.title}</h2>
              <p className="text-sm md:text-xl mb-8 w-full max-w-[600px]">{slide.description}</p>
              {slide.cta && <a href="/iletisim" className="bg-red-800 border-2 border-white hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                {slide.cta}
              </a>}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}